import { Li, Text, Title, Ul } from "@clipboard-health/ui-react";
import { Button, Link, Stack } from "@mui/material";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useToast } from "@src/appV2/lib/Notifications/Toasts/useToast";
import { openBrowser } from "@src/appV2/lib/utils/capacitor/openBrowser";
import { useCountdownTimer } from "@src/appV2/lib/utils/useCountdownTimer";
import { parseISO } from "date-fns";
import { format } from "date-fns-tz";
import { useState } from "react";

import { useAssessmentConfirmedNotificationIssue } from "../../api/useAssessmentConfirmedNotificationIssue";
import { useSkillsAssessmentFeatureFlags } from "../../api/useSkillsAssessmentFeatureFlags";
import { type SkillsAssessment } from "../../types";
import { ResendScheduledAssessmentTextModal } from "./ResendScheduledAssessmentTextModal";

interface Props {
  scheduledFor: string;
  showTimezone: boolean;
  onContinue: () => void;
  qualificationName?: string;
  assessmentId?: string;
  assessment?: SkillsAssessment;
}

export function OnboardingStatusScheduled(props: Props) {
  const { scheduledFor, onContinue, qualificationName, showTimezone, assessmentId, assessment } =
    props;
  const { skillsAssessmentConfig } = useSkillsAssessmentFeatureFlags();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { mutateAsync: resendText, isLoading } = useAssessmentConfirmedNotificationIssue();

  const { countdownIsLoading, startTimer, secondsLeft } = useCountdownTimer({
    durationInSeconds: 60,
  });
  const { showSuccessToast } = useToast();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleResend = async () => {
    await resendText({
      assessmentId: assessmentId ?? "",
    });
    logEvent(APP_V2_USER_EVENTS.SKILLS_ASSESSMENTS_RESEND_TEXT_CLICKED, {
      assessmentId,
    });
    handleCloseModal();
    showSuccessToast("Assessment link resent! (You will receive a new text shortly)");
    startTimer();
  };

  return (
    <Stack justifyContent="space-between" width="100%" height="100%">
      <Stack spacing={4}>
        <Title component="h1">
          You&apos;ve booked an assessment for{" "}
          <b>
            {format(parseISO(scheduledFor), "MMMM do")} at{" "}
            {format(parseISO(scheduledFor), `h:mmaaa${showTimezone ? " z" : ""}`)}
          </b>
          .
        </Title>
        <Stack paddingX={1}>
          <Text sx={{ lineHeight: "24px" }}>
            We&apos;ve sent you a text with your assessment link. Please open it at your scheduled
            time.
          </Text>
          <Ul>
            <Li>
              <Text bold sx={{ lineHeight: "24px" }}>
                Treat it like an interview: we expect you to be on time and give it your best
                effort.
              </Text>
            </Li>
            <Li>
              <Text sx={{ lineHeight: "24px" }}>
                You need to pass to book {qualificationName} shifts.
              </Text>
            </Li>
            <Li>
              <Text sx={{ lineHeight: "24px" }}>
                It takes {assessment?.attributes.videoAsk.estimatedTimeToCompleteInMinutes} minutes
                - good luck!
              </Text>
            </Li>
          </Ul>
          <Link
            sx={{ cursor: "pointer" }}
            onClick={() => {
              void openBrowser(skillsAssessmentConfig.supportArticleLink);
            }}
          >
            Learn more about the assessment.
          </Link>
        </Stack>
      </Stack>
      <Stack gap={2}>
        <Button
          variant="contained"
          sx={{ fontSize: 16, borderRadius: 84 }} // Override font size to match design
          onClick={onContinue}
        >
          Continue
        </Button>
        <Text
          sx={{
            textDecoration: "underline",
            cursor: "pointer",
          }}
          alignSelf="center"
          onClick={handleOpenModal}
        >
          I haven&apos;t received a text
        </Text>
      </Stack>
      <ResendScheduledAssessmentTextModal
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        handleResend={handleResend}
        isResending={isLoading}
        countdownIsLoading={countdownIsLoading}
        secondsLeft={secondsLeft}
      />
    </Stack>
  );
}
