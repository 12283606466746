import { Li, LoadingButton, Text, Title, Ul, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Link, Stack } from "@mui/material";
import { APP_V2_USER_EVENTS, openBrowser } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { addHours, differenceInHours, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { DateTimePicker } from "../../../lib/DatePicker";
import { useScheduleAssessment } from "../../api/useScheduleAssessment";
import { useSkillsAssessmentFeatureFlags } from "../../api/useSkillsAssessmentFeatureFlags";
import { type AssessmentQualification, useSkillsAssessments } from "../../api/useSkillsAssessments";
import { type SkillsAssessment } from "../../types";
import { adjustMinimumDatePickerTime } from "../../utils/adjustMinimumDatePickerTime";
import { SkipScheduleAssessment } from "./SkipScheduleAssessment";

interface Props {
  assessment: SkillsAssessment;
  onSuccess: () => void;
  nextSection?: string;
  onSkip: () => void;
}

export function OnboardingScheduleAssessment(props: Props) {
  const { assessment, onSuccess, nextSection, onSkip } = props;
  const history = useHistory();

  const { skillsAssessmentConfig, scheduledAssessmentConfig } = useSkillsAssessmentFeatureFlags();
  const {
    minimumLeadTimeToScheduleAssessmentInHours,
    maximumLeadTimeToScheduleAssessmentInHours,
    allowedTimeRange,
  } = scheduledAssessmentConfig;

  const [scheduledFor, setScheduledFor] = useState<string>();
  const skipAssessmentModalState = useModalState();

  const worker = useDefinedWorker();
  const { mutateAsync: scheduleAssessment, isLoading, isError } = useScheduleAssessment();

  const queryFailureHandler = () => {
    if (isDefined(nextSection)) {
      history.push(nextSection);
    }
  };

  // Added this entire backend call to get the qualification name for the assessment :(
  const { data: assessments } = useSkillsAssessments(
    {
      workerId: worker.userId,
      qualificationNames: skillsAssessmentConfig.availableQualificationNames,
      onFailure: queryFailureHandler,
    },
    {
      enabled: isDefined(worker.preference.qualification),
      staleTime: 0,
      cacheTime: 0,
    }
  );

  const assessmentQualification: AssessmentQualification | undefined = assessments?.included.find(
    (included) => included.type === "qualification"
  );

  useEffect(() => {
    if (isError && isDefined(nextSection)) {
      history.push(nextSection);
    }
  }, [isError, history, nextSection]);

  const [minDate, setMinDate] = useState(
    addHours(new Date(), minimumLeadTimeToScheduleAssessmentInHours)
  );
  const [maxDate, setMaxDate] = useState(
    addHours(new Date(), maximumLeadTimeToScheduleAssessmentInHours)
  );

  // update the min/max time every 10 seconds, otherwise the date picker will show dates that are no longer valid if the user spends a long time on the page
  useEffect(() => {
    const secondsInterval = 10;
    const intervalId = setInterval(() => {
      setMinDate(addHours(new Date(), minimumLeadTimeToScheduleAssessmentInHours));
      setMaxDate(addHours(new Date(), maximumLeadTimeToScheduleAssessmentInHours));
    }, secondsInterval * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [minimumLeadTimeToScheduleAssessmentInHours, maximumLeadTimeToScheduleAssessmentInHours]);

  return (
    <Stack justifyContent="space-between" width="100%" height="100%">
      <Stack spacing={4}>
        <Title component="h1">Schedule your assessment</Title>
        <Text>
          Pick a time to complete your video assessment - just come back to the app at that time.
        </Text>
        <Ul style={{ marginTop: 24 }}>
          <Li>
            <Text bold sx={{ lineHeight: "24px" }}>
              Treat it like an interview: we expect you to be on time and give it your best effort.
            </Text>
          </Li>
          <Li>
            <Text sx={{ lineHeight: "24px" }}>
              Passing is required to book {assessmentQualification?.attributes.name} shifts.
            </Text>
          </Li>
          <Li>
            <Text sx={{ lineHeight: "24px" }}>
              It takes {assessment.attributes.videoAsk.estimatedTimeToCompleteInMinutes} minutes -
              good luck!
            </Text>
          </Li>
        </Ul>
        <Text sx={{ lineHeight: "24px", cursor: "pointer" }}>
          <Link
            onClick={() => {
              void openBrowser(skillsAssessmentConfig.supportArticleLink);
            }}
          >
            Learn more about the assessment.
          </Link>
        </Text>
        <Stack spacing={3}>
          {isDefined(scheduledFor) ? (
            <Text sx={{ lineHeight: "24px" }}>You are scheduling your assessment for:</Text>
          ) : (
            <Text sx={{ lineHeight: "24px" }}>
              Schedule your assessment for the following time:
            </Text>
          )}
          <DateTimePicker
            minDateTime={adjustMinimumDatePickerTime(minDate)}
            maxDateTime={maxDate}
            disabled={isLoading}
            allowedTimeRange={allowedTimeRange}
            onConfirm={(value) => {
              setScheduledFor(value.toISOString());
            }}
          />
        </Stack>
      </Stack>
      <Stack gap={2}>
        <LoadingButton
          variant="contained"
          sx={{ fontSize: 16, borderRadius: 84 }} // Override font size to match design
          disabled={isLoading}
          isLoading={isLoading}
          onClick={async () => {
            if (!isDefined(scheduledFor)) {
              // Open modal to confirm that the worker wants to skip the assessment
              skipAssessmentModalState.openModal();
              return;
            }

            await scheduleAssessment({
              assessmentId: assessment.id,
              workerId: worker.userId,
              scheduledFor,
            });
            logEvent(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_SCHEDULED_EVENT, {
              assessmentId: assessment.id,
              workerId: worker.userId,
              scheduledFor,
              hoursUntilScheduled: differenceInHours(parseISO(scheduledFor), new Date()),
            });

            onSuccess();
          }}
        >
          Confirm
        </LoadingButton>
      </Stack>

      <SkipScheduleAssessment modalState={skipAssessmentModalState} onSkip={onSkip} />
    </Stack>
  );
}
